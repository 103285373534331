import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import getTitleId from "../helpers/getTitleId";
import "src/scss/components/_featureItemSection.scss";
import { AccruentImg } from "../commonComponents";
const defaultImage = "../../../static/placeholders/articles/ArticleImg1.jpg";

const FeatureItemsSection = ({ image, alt, bullets, title, sectionBody }) => {
  return (
    <React.Fragment>
      <div id="features" className="jumplinks-anchor tw-block tw-relative -tw-top-[132px] tw-invisible" id={getTitleId(title)}></div>
      <div className="columns">
        <div className="column">
          {title && (
            <h2 className="title title-5 has-text-centered has-text-accruent-sapphire">
              {title}
            </h2>
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
        {image ? (
            <AccruentImg
              file={image}
              objectFit="contain"
              className="features-image-component"
            />
          ) : (
            <StaticImage
              src={defaultImage}
              objectFit="contain"
              className="features-image-component"
              alt={alt}
              aspectRatio={1.3}
              decoding = "async"
            />
          )}
        </div>
        <div className="column is-6">
          <ul className="feature-items-list">
            {bullets.map((bullet, index) => {
              return (
                <li key={`reactKey_${bullet.body[0]}_${index}`}>
                  <h3 className="title-6 has-text-weight-semibold">{bullet.title}</h3>
                  <p>{bullet.body}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeatureItemsSection;
